

import { defineComponent } from "vue";
import Pagination from "../paging/Pagination.vue";

export default defineComponent({
  name: "RecentCards",
  components: {Pagination},
  // components: {},
  props: {
    cardData: {type: Array, required: true},
    currentPage: {type: Number, default: 1},
    total: {type: Number, default: 0},
    rowsPerPage: {type: Number, default: 10},
  },
  emits: ['current-change'],
  setup(_, {emit}) {
    const paginationChange = (val) => {
      emit('current-change', val)
    }
    return {
      paginationChange
    }
  }
})
